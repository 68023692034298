import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBInput } from "mdbreact";
import { withUserContext } from "../../../../contexts/UserContext";
import './ESignManager.scss';
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { validateEmail } from '../../../Common/Helper'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { RadioGroup, Radio } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import { downloadUrl, extractFilenameFromUrl, getAge } from "../../../Common/Helper";
import { ccInitialConfig, eSignInitialConfig } from './Config';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import messages from '../../../Common/Messages.json';

let fileFormData = null;
class ESignManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eSignDetails: this.formatESignDetails(JSON.parse(JSON.stringify({ ...eSignInitialConfig }))),
            agencyAddressOptions: [],
            repEmailOptions: [],
            eSignStatus: null,
            rider: this.props?.contractDetails?.is_rider,
            isFetchingEsignDetails: false,
            isFetchingExecutedContract: false,
            showSignatoryError: null,
            minor: false,
            inValidInput: false,
            invalidWarning: "Invalid input!!",
            defaultCC:[]
        };
    }

    getAge = (birthDate) => {
        if (birthDate) {
            var today = new Date();
            let birthDateFormatted = new Date(birthDate)
            var age = today?.getFullYear() - birthDateFormatted?.getFullYear();
            var m = today?.getMonth() - birthDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && today?.getDate() < birthDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            return age;
        } else {
            return 0
        }
    }

    componentDidMount() {
        if (this.props.contractDetails?.contract_adobe_status === 'UNSIGNED' ||
            this.props.contractDetails?.contract_adobe_status === 'SIGNED'
        ) {
            this.setState({ eSignStatus: this.props?.contractDetails?.contract_adobe_status }, () => {
                this.getEsignDetails();
            })
        } else {
            this.getRepAgencyAddressDetails();
        }
        this.getSignatoryDetails('SIGNATORY', 'signatories');
        
        let defaultCC = [{object_email :this.props?.performerDetails?.DefaultCCEmail ,object_name :this.props?.performerDetails?.DefaultCC_UserName}];
        this.setState({ defaultCC : defaultCC, minor: this.getAge(this.props?.performerDetails?.dob?.split('T')[0]) >= 18 ? false : true})
    }

    formatESignDetails = (response) => {
        if (response?.cc_recipients?.length === 0) {
            response?.cc_recipients.push(JSON.parse(JSON.stringify({ ...ccInitialConfig })));
        }
        if (this.props?.contractDetails?.is_already_signed == 1) {
            response.is_already_signed = this.props?.contractDetails?.is_already_signed || 0
        }

        return response;
    }

    getSignatoryDetails = (entity, optionName) => {
        // let url = `/animationGetEntitySearch?entity=${entity}&searchString=null`;
        // CasterService.getData(Constants.CasterServiceBaseUrl + url,
        //     this.props?.userContext?.active_tenant?.tenant_id)
        //     .then(response => {
        let formattedList = [];
        if (this.props?.signerDetails?.signatory_email) {
            formattedList?.push({
                object_type: 'SIGNATORY',
                object_email: this.props.signerDetails?.signatory_email,
                object_name: this.props.signerDetails?.signatory_name
            })
        }
        if (this.props?.signerDetails?.production_rep_email) {
            formattedList?.push({
                object_type: 'PROD. REP',
                object_email: this.props.signerDetails?.production_rep_email,
                object_name: this.props.signerDetails?.production_rep_name
            })
        }
        if (this.props?.signerDetails?.executive_email) {
            formattedList?.push({
                object_type: 'EXEC',
                object_email: this.props.signerDetails?.executive_email,
                object_name: this.props.signerDetails?.executive_name
            })
        }

        // formattedList = formattedList?.map(item => ({ object_type: item.type, object_email: item?.email, object_name: item?.name }))
        // if (this.state.eSignDetails?.esign_contract_type === 'Contract') {
        //     if (!this.state.rider ||
        //         this.state.eSignDetails?.is_already_signed == 1
        //     ) {
        //         formattedList = formattedList?.filter(item => item.object_type === 'SIGNATORY' || item.object_type === 'S')
        //     }
        // }
        this.setState({ [optionName]: formattedList })
        // },
        //     (err) => {
        //         console.log('Error is fetching details' + err);
        //     });
    }

    getRepAgencyAddressDetails = () => {
        let agencyAddressOptions = [...this.state.agencyAddressOptions];
        let repEmailOptions = [...this.state.repEmailOptions];
        this.props?.performerDetails?.representatives?.map(item => {
            let address = {
                label: `${item?.address_1 ? `${item?.address_1}` : ''}${item?.address_2 ? ` ${item?.address_2}` : ''}${item?.city ? ` ${item?.city}` : ''}${item?.state ? `${item?.city ? ', ' : ''}${item?.state}` : ''}${item?.zip ? ` ${item?.zip}` : ''}${(item?.country && item?.country !== 'United States') ? ` ${item?.country}` : ''}`,
                value: item?.representative_id
            }
            if (address?.label) {
                agencyAddressOptions?.push(address);
            }
            if (item?.representative_email) {
                let repEmail = {
                    label: item?.representative_email,
                    value: item?.representative_id
                }
                repEmailOptions?.push(repEmail);
            }
        })
        this.setState({
            agencyAddressOptions: agencyAddressOptions,
            repEmailOptions: repEmailOptions,
        }, () => {
            if (this.props?.contractDetails?.agency_address_on_contract) {
                let contractAddress = this.props?.contractDetails?.agency_address_on_contract?.replace(/\n/g, '');
                let agencyAddressValue = null;
                if (this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') {
                    agencyAddressValue = this.state.agencyAddressOptions?.find(item => item.label === contractAddress)?.value;
                } else {
                    agencyAddressValue = contractAddress
                }
                this.setState((prevState) => ({
                    eSignDetails: {
                        ...prevState.eSignDetails,
                        'agency_address_on_contract': agencyAddressValue,
                    },
                }));
            }
            if (this.props?.contractDetails?.rep_email_on_contract) {
                let repEmailValue = null;
                if (this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') {
                    repEmailValue = this.state.repEmailOptions?.find(item => item.label === this.props?.contractDetails?.rep_email_on_contract)?.value;
                } else {
                    repEmailValue = this.props?.contractDetails?.rep_email_on_contract
                }
                this.setState((prevState) => ({
                    eSignDetails: {
                        ...prevState.eSignDetails,
                        'rep_email_on_contract': repEmailValue,
                    },
                }));
            }
        })
    }

    getEsignDetails = () => {
        this.setState({ isFetchingEsignDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetEsignDetails?contractId=${this.props?.contractId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let eSignResponse = response?.data[0];
                eSignResponse['esign_contract_type'] = this.props?.contractDetails?.esign_contract_type || 'Contract';
                this.setState({
                    eSignDetails: eSignResponse,
                    isFetchingEsignDetails: false
                }, () => {
                    this.getRepAgencyAddressDetails();
                })
            },
                (err) => {
                    this.setState({ isFetchingEsignDetails: false })
                    console.log('Error is fetching e-sign details' + err);
                });
    }

    handleListOnchange = (field, value, index) => {
        let eSignDetails = { ...this.state.eSignDetails };
        eSignDetails.cc_recipients[index][field] = value;
        // if (field === 'object_email') {
        //     this.setState({ inValidInput: false })
        // }
        this.setState({ eSignDetails: eSignDetails })
    }

    handleOnchange = (field, value) => {
        let eSignDetails = { ...this.state.eSignDetails }
        if (field === 'rider') {
            this.setState({ [field]: value }, () => {
                this.getSignatoryDetails('SIGNATORY', 'signatories');
            });
        } else if (field === 'is_already_signed') {
            this.setState({
                eSignDetails: {
                    ...eSignDetails,
                    [field]: value === true ? 1 : 0,
                },
            }, () => {
                this.getSignatoryDetails('SIGNATORY', 'signatories');
            });
        } else if (field === 'esign_contract_type') {
            this.setState({
                eSignDetails: {
                    ...eSignDetails,
                    [field]: value,
                },
            }, () => {
                this.getSignatoryDetails('SIGNATORY', 'signatories');
            });
        } else {
            this.setState({
                eSignDetails: {
                    ...eSignDetails,
                    [field]: value,
                },
            });
        }
    };

    addCallback = (index) => {
        // this.setState({ inValidInput: false })
        let recipientDetails = [...this.state.eSignDetails?.cc_recipients];
        if (!validateEmail(recipientDetails[index]?.object_email)) {
            return;
        } else {
            recipientDetails.push(JSON.parse(JSON.stringify({ ...ccInitialConfig })))
            this.setState((prevState) => ({
                eSignDetails: {
                    ...prevState.eSignDetails,
                    ["cc_recipients"]: recipientDetails
                }
            }));
        }
    }

    removeCallback = (index) => {
        let eSignDetails = { ...this.state.eSignDetails }
        eSignDetails?.cc_recipients?.splice(index, 1);
        this.setState({ eSignDetails: eSignDetails });
    }

    handleAddRider = () => {
        this.setState({ openRiderEditor: true })
    }

    handleCancelRider = () => {
        this.setState({ rider: null });
    }

    getRecipientDetails = () => {
        let toRecipients = [];
        let talentRecipientObj = {}
        let talentSingatoryObj = {}
        talentRecipientObj['object_email'] = this.props?.performerDetails?.email;
        talentRecipientObj['object_type'] = "P";
        talentRecipientObj['object_name'] = this.props?.performerDetails?.talent_name;
        talentSingatoryObj['object_email'] = this.props?.signerDetails?.signatory_email;
        talentSingatoryObj['object_type'] = "S";
        talentSingatoryObj['object_name'] = this.props?.signerDetails?.signatory_name
        toRecipients?.push(talentRecipientObj);
        toRecipients?.push(talentSingatoryObj);
        let calculateAge = getAge(this.props?.performerDetails?.dob)
        if (calculateAge < 18 && this.props?.performerDetails?.primary_guardian_name && this.props?.performerDetails?.primary_guardian_email) {
            let guardianObj = {}
            guardianObj['object_email'] = this.props?.performerDetails?.primary_guardian_email;
            guardianObj['object_type'] = "G";
            guardianObj['object_name'] = this.props?.performerDetails?.primary_guardian_name;
            toRecipients?.push(guardianObj);
        }
        if (calculateAge < 18 && this.props?.performerDetails?.secondary_guardian_name && this.props?.performerDetails?.secondary_guardian_email) {
            let guardianObj = {}
            guardianObj['object_email'] = this.props?.performerDetails?.secondary_guardian_email;
            guardianObj['object_type'] = "G";
            guardianObj['object_name'] = this.props?.performerDetails?.secondary_guardian_name;
            toRecipients?.push(guardianObj);
        }
        if (this.state.eSignDetails?.esign_contract_type === 'Contract Packet') {
            toRecipients.push({
                'object_email': this.props?.signerDetails?.production_rep_email,
                'object_type': 'S',
                'object_name': this.props?.signerDetails?.production_rep_name
            })
        }
        if (this.state.rider && !this.state.eSignDetails?.is_already_signed) {
            toRecipients.push({
                'object_email': this.props?.signerDetails?.executive_email,
                'object_type': 'S',
                'object_name': this.props?.signerDetails?.executive_name
            })
        }
        return toRecipients;
    }

    formatCcRecipients = () => {
        let eSignDetails = { ...this.state.eSignDetails }
        eSignDetails?.cc_recipients?.push(this.state.defaultCC[0]);
        if (this.props?.performerDetails?.representatives?.length > 0) {
            let repObj = {}
            this.props?.performerDetails?.representatives?.map(item => {
                repObj['object_email'] = item.representative_email;
                repObj['object_type'] = "R";
                repObj['object_name'] = item.representative_name;
                eSignDetails?.cc_recipients?.push(repObj);
            })
        }
        return eSignDetails?.cc_recipients;
    }

    handleSubmit = (isPreviewToBeSent = false) => {
        this.setState({ showSignatoryError: null })
        if (!this.props?.performerDetails?.email || !this.props?.signerDetails?.signatory_email || !validateEmail(this.props?.signerDetails?.signatory_email) || !validateEmail(this.props?.performerDetails?.email)) {
            this.setState({ showSignatoryError: `Please enter talent and signatory's valid emails` })
            return
        }
        if (this.state.eSignDetails?.esign_contract_type === 'Contract Packet') {
            if (this.state.minor) {
                if (!(this.props?.performerDetails?.primary_guardian_email && validateEmail(this.props?.performerDetails?.primary_guardian_email)) && !(this.props?.performerDetails?.secondary_guardian_email && validateEmail(this.props?.performerDetails?.secondary_guardian_email))) {
                    this.setState({ showSignatoryError: `Please enter atleast one guardian's valid email` })
                    return
                }
                if (this.state.rider) {
                    if (!this.state.eSignDetails?.is_already_signed) {
                        if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email)) || !(this.props?.signerDetails?.executive_email && validateEmail(this.props?.signerDetails?.executive_email))) {
                            this.setState({ showSignatoryError: `Please enter prod rep and exec's valid email` })
                            return
                        }
                    } else {
                        if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email))) {
                            this.setState({ showSignatoryError: `Please enter prod rep's valid email` })
                            return
                        }
                    }
                } else {
                    if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email))) {
                        this.setState({ showSignatoryError: `Please enter prod rep's valid email` })
                        return
                    }
                }
            } else {
                if (this.state.rider) {
                    if (!this.state.eSignDetails?.is_already_signed) {
                        if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email)) || !(this.props?.signerDetails?.executive_email && validateEmail(this.props?.signerDetails?.executive_email))) {
                            this.setState({ showSignatoryError: `Please enter prod rep and exec's valid email` })
                            return
                        }
                    } else {
                        if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email))) {
                            this.setState({ showSignatoryError: `Please enter prod rep's valid email` })
                            return
                        }
                    }
                } else {
                    if (!(this.props?.signerDetails?.production_rep_email && validateEmail(this.props?.signerDetails?.production_rep_email))) {
                        this.setState({ showSignatoryError: `Please enter prod rep's valid email` })
                        return
                    }
                }
            }
        } else {
            if (this.state.minor) {
                if (!(this.props?.performerDetails?.primary_guardian_email && validateEmail(this.props?.performerDetails?.primary_guardian_email)) && !(this.props?.performerDetails?.secondary_guardian_email && validateEmail(this.props?.performerDetails?.secondary_guardian_email))) {
                    this.setState({ showSignatoryError: `Please enter atleast one guardian's valid email` })
                    return
                }
                if (this.state.rider) {
                    if (!this.state.eSignDetails?.is_already_signed) {
                        if (!(this.props?.signerDetails?.executive_email && validateEmail(this.props?.signerDetails?.executive_email))) {
                            this.setState({ showSignatoryError: `Please enter prod  exec's valid email` })
                            return
                        }
                    }
                }
            } else {
                if (this.state.rider) {
                    if (!this.state.eSignDetails?.is_already_signed) {
                        if (!(this.props?.signerDetails?.executive_email && validateEmail(this.props?.signerDetails?.executive_email))) {
                            this.setState({ showSignatoryError: `Please enter prod  exec's valid email` })
                            return
                        }
                    }
                }
            }
        }
        let searchPayload = JSON.parse(JSON.stringify({ ...this.state.eSignDetails }));
        searchPayload.talent_name = this.props?.performerDetails?.talent_name;
        searchPayload.show_id = this.props?.showId;
        searchPayload.show_type = this.props?.showType;
        searchPayload.show_name = this.props?.showName;
        searchPayload.show_season_id = this.props?.showSeasonId || null;
        searchPayload.season_name = this.props?.seasonName || null;
        searchPayload.episode_id = this.props?.episodeId;
        searchPayload.episode_name = this.props?.episodeName;
        searchPayload.episodeGlCode = this.props?.contractDetails?.gl_code;
        searchPayload.seasonEndYear = this.props?.seasonDetails?.end_year;
        searchPayload.seasonStartYear = this.props?.seasonDetails?.start_year;
        searchPayload.performer_id = this.props?.performerId;
        searchPayload.contract_id = this.props?.contractId;
        searchPayload.filename =   this.state.eSignDetails?.esign_contract_type === 'Contract Packet' ? `${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract Packet ${this.props?.contractDetails?.file_last_name}`
        :`${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract ${this.props?.contractDetails?.file_last_name}`;
        searchPayload.is_rider = this.state.rider ? 1 : 0;
        searchPayload.reportName = this.getReportNameByReportType(this.state.eSignDetails?.esign_contract_type,this.props?.contractDetails?.contract_type);
        searchPayload.template_name = this.state.eSignDetails?.esign_contract_type === 'Contract Packet' ? 'rptContractPacket' : 'rptContract';
        // searchPayload.agency_address_on_contract = this.state.agencyAddressOptions?.find(item => item.value === searchPayload.agency_address_on_contract)?.label;
        let selectedRep = this.props?.performerDetails?.representatives?.find(item => item.representative_id === searchPayload?.agency_address_on_contract);
        searchPayload.agency_address_on_contract = selectedRep ? `${selectedRep?.address_1 ? `${selectedRep?.address_1}` : ''}${selectedRep?.address_2 ? ` \n${selectedRep?.address_2}` : ''}${selectedRep?.city ? ` \n${selectedRep?.city}` : ''}${selectedRep?.state ? `${selectedRep?.city ? ', ' : ''}${selectedRep?.state}` : ''}${selectedRep?.zip ? ` ${selectedRep?.zip}` : ''}${(selectedRep?.country && selectedRep?.country !== 'United States') ? ` \n${selectedRep?.country}` : ''}` : null;
        searchPayload.rep_email_on_contract = this.state.repEmailOptions?.find(item => item.value === searchPayload.rep_email_on_contract)?.label;
        searchPayload.to_recipients = this.getRecipientDetails()
        searchPayload.cc_recipients = this.formatCcRecipients()
        searchPayload.cc_recipients = searchPayload?.cc_recipients?.filter(item => item.object_email !== null && item.object_name !== null);
        if (searchPayload.cc_recipients.length > 0) {
            if (!searchPayload.cc_recipients?.every(item => validateEmail(item?.object_email))) {
                this.setState({ showSignatoryError: `Please enter valid emails in cc` })
                return
            }
        }
        if (isPreviewToBeSent) {
            searchPayload.document_type = 'Preview';
            searchPayload.preview = 1;
        }
        if (this.state.eSignDetails?.esign_contract_type === 'Contract Packet') {
            searchPayload['contract_text'] = this.props?.contractDetails?.notes;
            searchPayload['account_details'] = null;
        }
        // let signatories = searchPayload?.to_recipients?.filter(item => item.object_type === 'S');
        // if (signatories?.length == 0) {
        //     this.setState({ showSignatoryError: true })
        //     return;
        // }
        // if (this.state.eSignDetails?.esign_contract_type === 'Contract Packet' && signatories?.length < 2) {
        //     this.setState({ showSignatoryError: true })
        //     return;
        // }
        // if (this.state.eSignDetails?.esign_contract_type === 'Contract' && this.state.rider && !this.state.eSignDetails?.is_already_signed) {
        //     if (signatories?.length < 2) {
        //         this.setState({ showSignatoryError: true })
        //         return;
        //     }
        // }
        let load = {
            payload: "animationPostTemplateToAdobe",
            reportName: this.getReportNameByReportType(this.state.eSignDetails?.esign_contract_type,this.props?.contractDetails?.contract_type),
            fileName: this.state.eSignDetails?.esign_contract_type === 'Contract Packet' ? `${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract Packet ${this.props?.contractDetails?.file_last_name}`
            :`${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract ${this.props?.contractDetails?.file_last_name}`,
            encryptedString: btoa(unescape(encodeURIComponent(JSON.stringify(searchPayload)))),
            showHtml: 0,
            encryptiontype: 1
        };
        if (isPreviewToBeSent) {
            this.setState({ isPreviewLoading: true })

        } else {
            this.setState({ isInitiatingEsign: true })
        }
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    console.log("check the response of esign", response)
                    if (!isPreviewToBeSent) {
                        this.props?.notificationComponent(true, "success", "refresh-contract");
                    } else {
                        let url = response?.body?.replace(/[" \\]/g, '');
                        const filename = extractFilenameFromUrl(url);
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(url, decodedFile);
                    }
                    this.setState({ isInitiatingEsign: false, isPreviewLoading: false })
                }
            })
        );
    }

    getReportNameByReportType(reportType, contractType) {
        if (reportType?.toLowerCase() === 'contract') {
            let contractReportName = null;
            if(this.props?.showTypeDropDown.toLowerCase() === "theatrical"){
                contractReportName = "rptFeatureContract";
            }else if (contractType === "Day Player") {
                contractReportName = "rptContract";
            } else if (contractType === "Sag Daily") {
                contractReportName = "rptSagDailyContract";
            }
            return contractReportName;
        } else if (reportType?.toLowerCase() === 'contract packet') {
            let contractPacketReportName = null;
            if(this.props?.showTypeDropDown.toLowerCase() === "theatrical"){
                contractPacketReportName = "rptFeatureContractPacket";
            }else if (contractType === "Day Player") {
                contractPacketReportName = "rptContractPacket";
            } else if (contractType === "Sag Daily") {
                contractPacketReportName = "rptSagDailyContractPacket";
            }
            return contractPacketReportName;
        } 
    }

    getRecipientStatus = (type, email = null) => {
        email = email?.toLowerCase()
        let toRecipients = [...this.state.eSignDetails?.to_recipients];
        let recipient = null;
        if (email) {
            recipient = toRecipients?.find(item => item.object_type === type && item.object_email === email);
        } else {
            recipient = toRecipients?.find(item => item.object_type === type);
        }
        return recipient?.status === 'COMPLETED' ? 'Signed' : (recipient?.status === 'INITIATED' ? 'Has Not Signed' : 'Signer');
    }

    handleExecutedContract = () => {
        let payload = {
            "moduleType": "esign",
            "show_type": this.props?.showType,
            "show_id": this.props?.showId,
            "show_name": this.props?.showName,
            "show_season_id": this.props?.showSeasonId,
            "season_name": this.props?.seasonName,
            "episode_id": this.props?.episodeId,
            "episode_name": this.props?.episodeName,
            // "filename": this.state.eSignDetails?.esign_contract_type === 'Contract Packet'
            // ? `${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract Packet ${this.props?.contractDetails?.file_last_name}`
            // : `${this.props?.contractDetails?.file_show_name} ${this.props?.contractDetails?.file_gl} Contract ${this.props?.contractDetails?.file_last_name}`,
            "filename":this.props?.contractDetails?.file_name,
            "contract_id": this.props?.contractDetails?.contract_id,
            "contract_adobe_status": this.props?.contractDetails?.contract_adobe_status 
        }
        this.setState({ isFetchingExecutedContract: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetFiles?input=${btoa(unescape(encodeURIComponent(JSON.stringify(payload))))}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("check the get files response", response);
                if (response.status == 200) {
                    let url = response?.data?.replace(/[" \\]/g, '');
                    const filename = extractFilenameFromUrl(url);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(url, decodedFile);
                    this.setState({ isFetchingExecutedContract: false })
                } else {
                    this.setState({ isFetchingExecutedContract: false })
                }

            },
                (err) => {
                    this.setState({ isFetchingExecutedContract: false })
                    console.log('Error is fetching files' + err);
                });
    }

    getFiles = (isLoadFile = false) => {
        let payload = {
            "moduleType": "Rider",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.props?.episodeName,
            "episodeGlCode": this.props?.contractDetails?.gl_code,
            "contract_id": this.props?.contractId
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetFiles?input=${btoa(JSON.stringify(payload))}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                if (!isLoadFile) {
                    console.log("check the get files response", response);
                    let url = response?.data;
                    const filename = extractFilenameFromUrl(url);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(url, decodedFile);
                } else {
                    if (response.status == 200) {
                        this.setState({ showAttached: true })
                    }
                }
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    uploadRider = (file) => {
        if (file?.type === 'application/pdf') {
            fileFormData = new FormData();
            fileFormData.append("files", file);
            fileFormData.append("contractId", this.props?.contractId);
            fileFormData.append('showId', this.props?.showId);
            fileFormData.append('showName', this.props?.showName);
            fileFormData.append('seasonStartYear', this.props.seasonDetails?.start_year);
            fileFormData.append('seasonEndYear', this.props.seasonDetails?.end_year);
            fileFormData.append('episodeName', this.props?.episodeName);
            fileFormData.append('episodeGlCode', this.props?.contractDetails?.gl_code);
            fileFormData.append('moduleType', 'Rider');
            CasterService.uploadFileFormData(Constants.CasterServiceBaseUrl + `/animationUploadFiles`,
                fileFormData)
                .then(response => {
                    if (response?.data?.status == 200) {
                        this.setState({ rider: 1 }, () => {
                            this.getSignatoryDetails('SIGNATORY', 'signatories');
                        })
                    }
                },
                    (err) => {
                        console.log('Error in uploading files' + err);
                    });
        } else {
            this.setState({ showRiderError: true })
        }
    }

    handleDeleteRider = () => {
        let payload = {
            "moduleType": "Rider",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.props?.episodeName,
            "episodeGlCode": this.props?.contractDetails?.gl_code,
            "contractId": this.props?.contractId
        }
        CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + `/animationDeleteFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("check the delete files response", response);
                if (response.status === 200) {
                    this.setState({ rider: 0 }, () => {
                        this.getSignatoryDetails('SIGNATORY', 'signatories');
                    })
                }
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    render() {
        const { isEditing, riderText } = this.state;
        let calculateAge = getAge(this.props?.performerDetails?.dob)
        return (
            <div>
                {this.state.isFetchingEsignDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="eSignManagerContainer">
                        {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') &&
                            <>
                                <MDBRow><span className="heading">Contract has not been sent.</span></MDBRow>
                                <MDBRow>
                                    <span className="preheading">
                                        To Preview the contract to be sent, click the 'Contract' button on the previous screen.
                                    </span>
                                </MDBRow>
                            </>
                        }
                        {this.state.eSignStatus === 'UNSIGNED' &&
                            <>
                                <MDBRow>
                                    <span className="contract-sent-heading">
                                        {`Contract was sent for signing on ${this.state.eSignDetails?.contract_sent_date}.`}
                                    </span>
                                </MDBRow>
                                <MDBRow>
                                    <span className="preheading">
                                        To Preview the sent contract, click the ‘Contract’ button on the previous screen.
                                    </span>
                                </MDBRow>
                                <MDBRow>
                                    <span className="preheading">
                                        To cancel signing, use the Adobe console.
                                    </span>
                                </MDBRow>
                            </>
                        }
                        {this.state.eSignStatus === 'SIGNED' &&
                            <>
                                <MDBRow>
                                    <span className="contract-sent-heading">
                                        {`Contract was sent for signing on ${this.state.eSignDetails?.contract_sent_date}.`}
                                    </span>
                                </MDBRow>
                                <MDBRow>
                                    <span className="contract-signed-heading">
                                        {`Contract was fully signed on ${this.state.eSignDetails?.contract_signed_date}.`}
                                    </span>
                                </MDBRow>
                            </>
                        }
                        <MDBRow><span className="recipient-label">Recipients</span></MDBRow>
                        <MDBRow>
                            <MDBCol md={2} className="paragraph pr-0"><strong>Talent</strong></MDBCol>
                            <MDBCol md={3} className="paragraph break-word">{this.props?.performerDetails?.talent_name}</MDBCol>
                            <MDBCol md={4} className="paragraph recipient-email">{this.props?.performerDetails?.email}</MDBCol>
                            <MDBCol md={3} className="green paragraph">{this.getRecipientStatus('P')}</MDBCol>
                        </MDBRow>
                        {(calculateAge < 18 && this.props?.performerDetails?.primary_guardian_name && this.props?.performerDetails?.primary_guardian_email) &&
                            <MDBRow>
                                <MDBCol md={2} className="paragraph pr-0"><strong>Guardian</strong></MDBCol>
                                <MDBCol md={3} className="paragraph break-word">{this.props?.performerDetails?.primary_guardian_name}</MDBCol>
                                <MDBCol md={4} className="paragraph recipient-email">{this.props?.performerDetails?.primary_guardian_email}</MDBCol>
                                <MDBCol md={3} className="green paragraph">{this.getRecipientStatus('G', this.props?.performerDetails?.primary_guardian_email)}</MDBCol>
                            </MDBRow>
                        }
                        {(calculateAge < 18 && this.props?.performerDetails?.secondary_guardian_name && this.props?.performerDetails?.secondary_guardian_email) &&
                            <MDBRow>
                                <MDBCol md={2} className="paragraph pr-0"><strong>Guardian</strong></MDBCol>
                                <MDBCol md={3} className="paragraph break-word">{this.props?.performerDetails?.secondary_guardian_name}</MDBCol>
                                <MDBCol md={4} className="paragraph recipient-email">{this.props?.performerDetails?.secondary_guardian_email}</MDBCol>
                                <MDBCol md={3} className="green paragraph">{this.getRecipientStatus('G', this.props?.performerDetails?.secondary_guardian_email)}</MDBCol>
                            </MDBRow>
                        }
                        {this.state.signatories?.map(item => (
                            (item.object_type === 'SIGNATORY' || (item.object_type === 'EXEC' && this.state.rider && !this.state.eSignDetails?.is_already_signed) || (item?.object_type === 'PROD. REP' && this.state.eSignDetails?.esign_contract_type === 'Contract Packet')) && <MDBRow>
                                <MDBCol md={2} className="paragraph pr-0">
                                    <strong>{(item.object_type === 'SIGNATORY' || item.object_type === 'S') ? 'Signatory' : (item.object_type === 'EXEC') ? 'Prod Exec' : 'Prod Rep.'}</strong>
                                </MDBCol>
                                <MDBCol md={3} className="paragraph break-word">{item.object_name}</MDBCol>
                                <MDBCol md={4} className="paragraph recipient-email">{item.object_email}</MDBCol>
                                <MDBCol md={3} className="green paragraph">{this.getRecipientStatus('S', item?.object_email)}</MDBCol>
                            </MDBRow>
                        ))}
                        {this.props?.performerDetails?.representatives?.map(item => (
                                item?.representative_email && (
                                    <MDBRow>
                                    <MDBCol md={2} className="paragraph pr-0"><strong>Rep. (CC)</strong></MDBCol>
                                    <MDBCol md={3} className="paragraph break-word">{item?.representative_name}</MDBCol>
                                    <MDBCol md={4} className="paragraph recipient-email">{item?.representative_email}</MDBCol>
                                    <MDBCol md={3} className="do-not-sign paragraph">Does Not Sign</MDBCol>
                                </MDBRow>
                                )
                        ))}
                        
                        {this.state.defaultCC.map(item => (
                            <>
                                {(item.object_name && item.object_email ) &&
                                    <MDBRow>
                                        <MDBCol md={2} className="paragraph pr-0"><strong>CC</strong></MDBCol>
                                        <MDBCol md={3} className="paragraph break-word">{item?.object_name}</MDBCol>
                                        <MDBCol md={4} className="paragraph recipient-email">{item?.object_email}</MDBCol>
                                        <MDBCol md={3} className="do-not-sign paragraph">Does Not Sign</MDBCol>
                                    </MDBRow>
                                }
                            </>
                        ))}
                        {this.state.eSignDetails?.cc_recipients?.map(item => (
                            <>
                                {(item.object_name && item.object_email && item.object_type === "C") &&
                                    <MDBRow>
                                        <MDBCol md={2} className="paragraph pr-0"><strong>CC</strong></MDBCol>
                                        <MDBCol md={3} className="paragraph break-word">{item?.object_name}</MDBCol>
                                        <MDBCol md={4} className="paragraph recipient-email">{item?.object_email}</MDBCol>
                                        <MDBCol md={3} className="do-not-sign paragraph">Does Not Sign</MDBCol>
                                    </MDBRow>
                                }
                            </>
                        ))}
                        {this.state.showSignatoryError &&
                            <p className="error-text">{this.state.showSignatoryError}</p>
                        }
                        {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') &&
                            <>
                                <hr></hr>
                                <MDBRow className="cc-recipient">
                                    <label className="paragraph"><strong>Add to CC Distribution</strong></label>
                                    {this.state.eSignDetails?.cc_recipients?.map((item, index) =>
                                        <>
                                            {item.object_type === "C" &&
                                                <>
                                                    <MDBCol md={5} className="cc-name pr-0">
                                                        <BasicTextField
                                                            placeholder="Name"
                                                            value={item?.object_name || ""}
                                                            onChange={(e) => {
                                                                this.handleListOnchange('object_name', e.target.value, index);
                                                            }}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={5} className="cc-email pl-0">
                                                        <BasicTextField
                                                            placeholder="Email"
                                                            value={item?.object_email || ""}
                                                            onChange={(e) => {
                                                                this.handleListOnchange('object_email', e.target.value, index);
                                                            }}
                                                            fieldValid={!validateEmail(item?.object_email) && item?.object_email}
                                                            inValidInput={messages.invalidInput}
                                                         showAsLabel={false}
                                                        />
                                                        {/* {
                                                            <div className="error">{this.state?.inValidInput ? this.state?.invalidWarning : ""}</div>
                                                        } */}
                                                    </MDBCol>
                                                    <MDBCol md={2} className={`${index === 0 ? 'firstRowIcons' : 'icons'} status-actions`}>
                                                        {this.state.eSignDetails?.cc_recipients.length - 1 === index ? (
                                                            <AddCircleIcon
                                                                className="ml-2 align-self-center"
                                                                fontSize="small"
                                                                onClick={() => this.addCallback(index)}
                                                            />
                                                        ) : null}
                                                        {!(this.state.eSignDetails?.cc_recipients.length === 1) ? (
                                                            <RemoveCircleIcon
                                                                className="ml-2 align-self-center"
                                                                fontSize="small"
                                                                onClick={() => this.removeCallback(index)}
                                                            />
                                                        ) : null}
                                                    </MDBCol>
                                                </>
                                            }
                                        </>
                                    )}

                                </MDBRow>
                            </>
                        }
                        <MDBRow>
                            <MDBCol md={9} className="esign-contract-type">
                                <FormLabel className="recipient-label">
                                    {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') ?
                                        <strong>Review Contract Options</strong> :
                                        <strong>Contract Options</strong>
                                    }
                                </FormLabel>
                                <RadioGroup
                                    row
                                    name="contract_to"
                                    size="small"
                                    value={this.state.eSignDetails?.esign_contract_type}
                                    onChange={(e) => this.handleOnchange('esign_contract_type', e.target.value)}
                                    disabled={(this.props?.contractDetails?.contract_adobe_status && this.props?.contractDetails?.esign_contract_type) || false}
                                >
                                    <FormControlLabel
                                        value="Contract Packet"
                                        control={<Radio size="small" />}
                                        label={<span className="paragraph-small">Send Contract Packet</span>}
                                        size="small"
                                    />
                                    <FormControlLabel
                                        value="Contract"
                                        control={<Radio size="small" />}
                                        label={<span className="paragraph-small">Send Contract Only</span>}
                                        size="small"
                                    />
                                </RadioGroup>
                            </MDBCol>
                            <MDBRow>
                                <MDBCol md={4}>
                                    <span className="paragraph"><strong>Rider</strong></span>
                                </MDBCol>
                                <MDBCol md={3}></MDBCol>
                                <MDBCol md={5}>
                                    <span className="paragraph"><strong>Loanout</strong></span>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4} className='rider'>
                                    {!this.state.rider ? (
                                        <div>
                                            <span>
                                                {/* <MDBIcon
                                                        icon="plus-circle"
                                                        className="add-icon"
                                                        onClick={this.handleAddRider}
                                                    /> */}
                                                <BasicButton
                                                    variant="outlined"
                                                    icon="paperclip"
                                                    text="Add"
                                                    type="inline"
                                                    inputType="file"
                                                    multiple={false}
                                                    id={'upload-rider'}
                                                    uploadFile={(file) => this.uploadRider(file)}
                                                />
                                            </span>
                                            {/* <span className="add-text" onClick={this.handleAddRider}>Add</span> */}
                                        </div>
                                    ) : (
                                        <MDBRow>
                                            <MDBCol md={5} className="attached-text" onClick={this.getFiles}>Attached</MDBCol>
                                            {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') ?
                                                <MDBCol>
                                                    {/* <span>
                                                                <MDBIcon
                                                                    icon="pen"
                                                                    className="text-success"
                                                                    onClick={() => this.setState({ openRiderEditor: true })}
                                                                />
                                                            </span>
                                                            <span>
                                                                <MDBIcon
                                                                    icon="times"
                                                                    className="text-danger"
                                                                    onClick={this.handleCancelRider}
                                                                />
                                                            </span> */}
                                                    <MDBRow>
                                                        <MDBCol md={1}>
                                                            <BasicButton
                                                                variant="outlined"
                                                                icon="exchange-alt"
                                                                type="inline"
                                                                inputType="file"
                                                                multiple={false}
                                                                id={'upload-rider-replace'}
                                                                uploadFile={(file) => this.uploadRider(file)}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol className="pl-0">
                                                            <MDBIcon
                                                                icon="times"
                                                                className="text-danger"
                                                                onClick={this.handleDeleteRider}
                                                            />
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol> : ''
                                            }
                                        </MDBRow>
                                    )}
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicCheckbox
                                        id={"is_already_signed"}
                                        label={"Already Signed"}
                                        checked={this.state.eSignDetails?.is_already_signed === 1 ? true : false}
                                        onChange={(e) =>
                                            this.handleOnchange("is_already_signed", e.target.checked)
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={5}>
                                    {this.props?.performerDetails?.loanout_company ?
                                        <span className="paragraph">Attached</span> :
                                        <span className="paragraph">Not Attached</span>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') ?
                                    <MDBCol md={6}>
                                        <SelectField
                                            id={"Agency"}
                                            label="Agency Address (to print on contract)"
                                            placeholder={"-All Agency Address-"}
                                            multiple={true}
                                            value={this.state.eSignDetails?.agency_address_on_contract ?? this.state.agencyAddressOptions[0]?.value}
                                            options={this.state.agencyAddressOptions}
                                            width={300}
                                            onChange={(e, value) => this.handleOnchange('agency_address_on_contract', e.target.value)}
                                        />
                                    </MDBCol> :
                                    <MDBCol md={12}>
                                        <BasicLabel text={"Agency Address (to print on contract)"} />
                                        <p className="pre-line">{this.state.eSignDetails?.agency_address_on_contract}</p>
                                    </MDBCol>
                                }
                            </MDBRow>
                            <MDBRow>
                                {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') ?
                                    <MDBCol md={6}>
                                        <SelectField
                                            id={"rep-email"}
                                            label="Representive Email (to print on contract)"
                                            placeholder={"-Select-"}
                                            multiple={true}
                                            value={this.state.eSignDetails?.rep_email_on_contract ?? this.state.repEmailOptions[0]?.value}
                                            options={this.state.repEmailOptions}
                                            width={300}
                                            onChange={(e, value) => this.handleOnchange('rep_email_on_contract', e.target.value)}
                                        />
                                    </MDBCol> :
                                    <MDBCol md={12}>
                                        <BasicLabel text={"Representive Email (to print on contract)"} />
                                        <p>{this.state.eSignDetails?.rep_email_on_contract}</p>
                                    </MDBCol>
                                }
                            </MDBRow><br />
                            <MDBRow>
                                {this.state.eSignStatus === 'UNSIGNED' ?
                                    <MDBCol md={6} className="preview-sent-contract">
                                        <BasicButton
                                            className="btnwidth"
                                            variant="contained"
                                            text={this.state.isPreviewLoading ?
                                                <CircularProgress color="inherit" size={18} /> :
                                                "Preview Sent Contract"
                                            }
                                            onClick={() => this.handleSubmit(true)}
                                        />
                                    </MDBCol> :
                                    <>
                                        {this.state.eSignStatus === 'SIGNED' ?
                                            '' :
                                            <MDBCol md={6} className="preview-contract">
                                                <BasicButton
                                                    className="btnwidth"
                                                    variant="contained"
                                                    text={this.state.isPreviewLoading ?
                                                        <CircularProgress color="inherit" size={18} /> :
                                                        "Preview Contract to be Sent"
                                                    }
                                                    onClick={() => this.handleSubmit(true)}
                                                />
                                            </MDBCol>
                                        }
                                    </>
                                }
                            </MDBRow>
                            {(this.state.eSignStatus !== 'UNSIGNED' && this.state.eSignStatus !== 'SIGNED') &&
                                <MDBRow className="p-2 margin">
                                    <MDBCol md={7} lg={7}></MDBCol>
                                    <MDBCol md={3} lg={3}>
                                        <BasicButton
                                            disabled ={!this.props?.performerDetails?.email}
                                            className="btnwidth"
                                            variant="contained"
                                            text={this.state.isInitiatingEsign ?
                                                <CircularProgress color="inherit" size={18} /> :
                                                "Initiate Signing"
                                            }
                                            icon={"Create"}
                                            onClick={() => this.handleSubmit(false)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            type="inline"
                                            variant="outlined"
                                            text={"Cancel"}
                                            onClick={() => {
                                                this.props?.onClose();
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            }
                            {this.state.eSignStatus === 'SIGNED' &&
                                <MDBRow>
                                    <MDBCol md={8} lg={8}></MDBCol>
                                    <MDBCol md={4} lg={4} className="executed-contract-btn">
                                        <Button
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            variant="contained"
                                            startIcon={<MDBIcon icon="eye" size="small" />}
                                            onClick={this.handleExecutedContract}
                                        >
                                            {"Executed Contract"}
                                        </Button>
                                    </MDBCol>
                                </MDBRow>
                            }
                        </MDBRow>
                        {this.state.openRiderEditor && (
                            <MainViewModalComponent
                                open={this.state.openRiderEditor}
                                handleClose={() => this.setState({ openRiderEditor: false })}
                                headerText={"Edit Rider"}
                                mode={"Edit"}
                                modalName={"Rider"}
                                rider={this.props?.contractDetails?.rider}
                                handleOnChange={this.handleOnchange}
                            />
                        )}
                    </MDBContainer>
                )
                }
            </div>
        );
    }
}

export default withUserContext(ESignManager);
