import React from 'react';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditorWithLineHeight.scss';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
Quill.register(Quill.import("attributors/style/align"), true);
var Block = Quill.import('blots/block');
import { withUserContext } from '../../../../contexts/UserContext';
Block.tagName = 'div';
Quill.register(Block);

  const Size = Quill.import("formats/size");
  Size.whitelist = ["small", "medium", "large"];
  Quill.register(Size, true);

// Add fonts to whitelist
const Font = Quill.import('formats/font');
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ["arial", "helvetica"];
Quill.register(Font, true);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    //"indent",
    "color",
    "line-height"
  ];
  var Parchment = Quill.import("parchment");
let config = {
    scope: Parchment.Scope.INLINE,
    whitelist: ["1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0"]
  };
  let lineHtClass = new Parchment.Attributor.Class(
    "line-height",
    "ql-line-height",
    config
  );
  let lineHtStyle = new Parchment.Attributor.Style(
    "line-height",
    "line-height",
    config
  );
  Parchment.register(lineHtClass);
  Parchment.register(lineHtStyle);
  const QuillToolbar = (props) => (
    <div id={props?.id}>
      <select class="ql-font">
        <option value="arial">Arial</option>
        <option value="helvetica">Helvetica</option>
      </select>
      <span className="ql-formats">
      
    <select className="ql-size" defaultValue="medium">
      <option value="small">Small</option>
      <option value="medium">Medium</option>
      <option value="large">Large</option>
    </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
  
      <select class="ql-line-height">
      <option value="1.0"></option>
      <option value="1.5"></option>
      <option value="2.0"></option>
      <option value="2.5"></option>
      <option value="3.0"></option>
      <option value="3.5"></option>
      <option value="4.0"></option>
    </select>
    </div>
  );
  
  
  var getCurrentFormat = function(scope) {
    return scope.quill.getFormat(
      scope.quill.selection.savedRange
    );
  };
  
  var getCurrentLineHeight = function(scope) {
    var currentValue = getCurrentFormat(scope)['line-height'] || '1.0';
    return parseFloat(currentValue);
  };
  
   var increaseLineHeight = function(scope) {
     console.log(getCurrentFormat(scope));
     var newLineHeight = ((getCurrentLineHeight(scope) * 10) + 1) / 10;
     scope.quill.format('line-height', newLineHeight + '', 'user');
     console.log(getCurrentFormat(scope));
   };
  let modules= {
    toolbar: {
      container: "#toolbar-container",
      handlers: {
        increaseLineHeight : () => {
          increaseLineHeight(this);
        }
      }
    }
  }
class RichTextEditorWithLineHeight extends React.Component {

  constructor(props, context) {
    super(props, context);
  }
  
  
  render() {
    // modules.toolbar.container = `#toolbar${this.props?.id}`
    return (
      <div className="text-editor edit-rider-exhibit">
        <QuillToolbar id={"toolbar-container"}></QuillToolbar>
        <ReactQuill id={"toolbar-container-quill"}
           readOnly ={this.props.readOnly}
          {...(this.props?.value ? { value: this.props?.value ? this.props?.value : '<div></div>' } : {})}
          {...(this.props?.defaultValue ? { defaultValue: this.props?.defaultValue } : {})}
          {...(this.props?.onChange ? { onChange: this.props?.onChange } : {})}
          theme="snow"
          formats={formats}
          modules={modules}
        />
      </div>
    )
  }

}
export default withUserContext(RichTextEditorWithLineHeight);
