
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './Config';
import Show from '../../../pages/Modals/Show';
import './Modal.scss';
import { withUserContext } from "../../../../contexts/UserContext"
import MessageModal from "../../../components/SharedComponents/MessageModal"
import Episode from '../../../pages/Modals/Episode/Episode';
import Season from '../../../pages/Modals/Season/Season';
import TalentRecord from '../../../pages/Modals/TalentRecord/TalentRecord'
import Contract from '../../../pages/Modals/Contract/Contract'
import ESignManager from '../../../pages/Modals/ESignManager/ESignManager'
import CreateNewShow from '../../../pages/Modals/CreateNewShow/CreateNewShow'
import Rider from '../../../pages/Modals/Rider/Rider'
import AddRepresentive from '../../../pages/Modals/AddRepresentive/AddRepresentive';
import AddTalentModal from '../../../pages/Modals/AddTalentModal/AddTalentModal';
import ExistingFile from '../../../pages/Modals/ExistingFile/ExistingFile';
import NewUser from '../../../pages/Modals/NewUser/NewUser';
import AddCompanyRepresentive from '../../../pages/Modals/AddCompanyRepresentive/AddCompanyRepresentive';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import LookupValues from '../../../components/LookupValue/LookupValues';

class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            saveFlag: true,
            showNotifSuccess: false,
            showNotifFail: false,
            saveFlag: false,
            saveNotificationFlag: false
        }
        this.submitButtonRef = React.createRef();
    }

    // componentDidMount() {
    //     document.getElementById("sidenav").setAttribute("disabled", true);
    // }

    // componentWillUnmount() {
    //     document.getElementById("sidenav").removeAttribute("disabled");
    // }

    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        if (this.state.refreshPageValue) {
            this.props?.toggleRefresh();
        }
        // if (this.state.saveFlag) {
        this.props?.handleClose();
        // }

    }
    deletePost = (save_flag) => {
        this.setState({ saveFlag: save_flag });
    }
    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error", refreshValue = null) => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value, refreshPageValue: refreshValue });
            case "fail": return this.setState({ showFail: value });
        }
    }

    notificationComponent = (value, mode = "fail", refreshValue = null) => {
        switch (mode) {
            case "success": return this.setState({ showNotifSuccess: value, saveNotificationFlag: true, refreshPageValue: refreshValue });
            case "fail": return this.setState({ showNotifFail: value });
            case "remove": return this.setState({ showRemoveProfile: value });
        }
    }
   
    handleResetNotify = () => {
        this.setState({ showNotifFail: false, showNotifSuccess: false });
        if (this.state.saveNotificationFlag) {
            this.props?.handleClose();
        }
        if (this.state.refreshPageValue) {
            this.props?.toggleRefresh();
        }
    }

    getModalContent = () => {
        switch (this.props?.modalName) {

            case 'Show': return <Show
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                showId={this.props?.showId}
                toggleRefresh={this.props?.toggleRefresh}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Episode': return <Episode
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                showId={this.props?.showId}
                showSeasonId={this.props?.showSeasonId}
                episodeId={this.props?.episodeId}
                toggleRefresh={this.props?.toggleRefresh}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Season': return <Season
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                showSeasonId={this.props?.showSeasonId}
                showId={this.props?.showId}
                toggleRefresh={this.props?.toggleRefresh}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Talent Record': return <TalentRecord
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                showId={this.props?.showId}
                showSeasonId={this.props?.showSeasonId}
                episodeId={this.props?.episodeId}
                performerId={this.props?.performerId}
                talentDetails={this.props?.talentDetails}
                toggleRefresh={this.props?.toggleRefresh}
                updatePerformerDetails={this.props?.updatePerformerDetails}
                showEpisodeDetails={this.props?.showEpisodeDetails}
                notificationComponent={this.notificationComponent.bind(this)}
                navigationCallback={this.props?.navigationCallback}
                deletePerformer={this.deletePerformer.bind(this)}
            />
            case 'Contract': return <Contract
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                showSeasonId={this.props?.showSeasonId}
                contractId={this.props?.contractId}
                performerId={this.props?.performerId}
                talentDetails={this.props?.talentDetails}
                toggleRefresh={this.props?.toggleRefresh}
                contractFromTalentRecord={this.props?.contractFromTalentRecord}
                notificationComponent={this.notificationComponent.bind(this)}
                navigationCallback={this.props?.navigationCallback}
                {...this.props}
            />
            case 'ESignManager': return <ESignManager
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                performerDetails={this.props?.performerDetails}
                contractDetails={this.props?.contractDetails}
                seasonDetails={this.props?.seasonDetails}
                showId={this.props?.showId}
                showType={this.props?.showType}
                showName={this.props?.showName}
                showSeasonId={this.props?.showSeasonId}
                seasonName={this.props?.seasonName}
                episodeId={this.props?.episodeId}
                episodeName={this.props?.episodeName}
                performerId={this.props?.performerId}
                contractId={this.props?.contractId}
                notificationComponent={this.notificationComponent.bind(this)}
                signerDetails={this.props?.signerDetails}
                showTypeDropDown={this.props?.showTypeDropDown}
            />
            case 'CreateNewShow': return <CreateNewShow
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Rider': return <Rider
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                rider={this.props?.rider}
                handleOnChange={this.props?.handleOnChange}
            />
            case 'AddRepresentative': return <AddRepresentive
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                handleOnChange={this.props?.handleOnChange}
                handleRepData={this.props.handleRepData}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'NewUser': return <NewUser
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                handleOnChange={this.props?.handleOnChange}
                toggleRefresh={this.props?.toggleRefresh}
                userId={this.props?.userId}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'AddTalentModal': return <AddTalentModal
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                handleOnChange={this.props?.handleOnChange}
                repId={this.props?.repId}
                RepCompanyId={this.props?.RepCompanyId}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'AddCompanyRepresentive': return <AddCompanyRepresentive
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                handleOnChange={this.props?.handleOnChange}
                companyId={this.props?.companyId}
                setPostFlag={this.setPostFlag}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case "Lookup Values": return <LookupValues
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
            />
            case 'ExistingFile': return <ExistingFile
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                handleFileConfirm={this.props?.handleFileConfirm}
                handleFileCancel={this.props?.handleFileCancel}
                isPosting={this.props?.isPosting}
                createdBy={this.props?.createdBy}
                createdDate={this.props?.createdDate}
            />
        }
    }

    getWidthForModal = () => {
        switch (this.props?.modalName) {
            // case 'New Season': return 'newEditSeason';
            // case 'Episode': return 'newEditEpisode';
            // case 'Add Performer': return 'newEditPerformer';
            // case 'Roles': return 'newEditRoles';
            // case 'Data Query': return 'dataQuerySearch';
            // case 'Manage Lookups': return 'managelookup';
            default: return '';

        }
    }


    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Show': return classes.newShow;
            default: return '';
        }
    }

    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'Show': return classes.newShow;
            case 'NewUser': return 'sm';
            case 'AddRepresentative': return 'sm';
            case 'AddTalentModal': return 'sm';
            case 'Contract': return 'md';
            case 'Episode': return 'md';
            case 'Rider': return 'lg';
            case 'Season': return 'sm';
            case 'ESignManager': return 'md';
            case 'Lookup Values': return 'sm';
            case 'CreateNewShow': return 'md';
            default: return 'sm';
        }
    }

    deletePerformer=(re)=>{
        this.props.deletePerformer("1");
        this.props?.handleClose();
    }

    render() {

        const { classes } = this.props;
        let severity = this.state.showNotifFail ? "error" : this.state.showNotifSuccess ? "success" : this.state.showRemoveProfile ? "success" : "";
        let messageNotification = this.state.showNotifSuccess ? 
            (this.props?.modalName === "ESignManager" ? "E-Sign successfully Initiated" : "The changes are saved successfully!") : 
            this.state.showNotifFail ? (this.props?.modalName === "ESignManager" ? "Failed to initiate E-Sign" : "Saving changes failed!") : 
                this.state.showRemoveProfile ? "Profile Image Removed Successfully" : "";
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
                    (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <div className={"MainViewModalContainer " + this.getWidthForModal()}>
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <NotificationComponent open={this.state.showNotifSuccess || this.state.showNotifFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()} className={this.getClasses()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                {this.props?.headerText !== 'Existing File' &&
                                                    <MDBRow className="ModalHeader" id={"modal_header"}>
                                                        <MDBCol 
                                                            md={`${(this.props?.modalName === 'AddCompanyRepresentive'  || this.props?.modalName === 'AddRepresentative')? 6 : 5}`} 
                                                            className="HeaderText" 
                                                        >
                                                            {this.props?.headerText}
                                                        </MDBCol>
                                                        <MDBCol 
                                                            md={`${(this.props?.modalName === 'AddCompanyRepresentive'  || this.props?.modalName === 'AddRepresentative')? 5 : 6}`} 
                                                            className="HeaderText"
                                                        ></MDBCol>
                                                        <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                    </MDBRow>
                                                }
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withUserContext(withStyles(useStyles)(MainViewModalComponent));
